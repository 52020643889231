
<template>
  <div class="container">
    <img
      src="../assets/Logo-growth.svg"
      class="logo"
      alt="Flash Growth, SDR impulsionado por IA"
    />
    <div class="input-group">
      <input
        type="text"
        v-model="userInput"
        placeholder="Digite sua consulta"
        class="input-field"
        @keyup.enter="fetchResponse"
      />
      <button @click="fetchResponse" class="button">Enviar</button>
      <img v-if="loading" src="../assets/gg--spinner.png" class="loader">
      <span v-if="loading">Carregando...</span>
    </div>
    
    <div v-if="responseData" class="results">
      <h2>Resultados:</h2>
      <!-- <div class="intent">
        <div class="intent-title">
          Intenção: {{ responseData.intents[0].name }}
        </div>
        <div class="confidence">
          Confiança: {{ (responseData.intents[0].confidence * 100).toFixed(0) }}%
        </div>
      </div> -->
      <div
        v-for="(entity, key) in responseData.entities"
        :key="key"
        class="entity"
      >
        <h3 class="entity-title">{{ formatEntityTitle(key) }}</h3>
        <ul>
          <li v-for="item in entity" :key="item.id">
            {{ item.body }}
            <span class="confidence"
              >(Confiança: {{ (item.confidence * 100).toFixed(0) }}%)</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";

const userInput = ref("");
let responseData = ref(null);
const loading = ref(false);

const formatEntityTitle = (title) => {
  const parts = title.split(":");
  return parts[parts.length - 1];
};
const fetchResponse = async () => {
  loading.value = true;
  try {
    const response = await axios.get(
      `https://api.wit.ai/message?v=20240418&q=${encodeURIComponent(
        userInput.value
      )}`,
      {
        headers: {
          Authorization: "Bearer XVZK75CETGCB4SF4ZR4K2PSOWAFY7FMQ",
        },
      }
    );
    responseData.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar resposta:", error);
  } finally {
    loading.value = false;
  }
};
</script>

<style>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;
  width: 30px;
}

.container {
  font-family: "Arial", sans-serif;
  max-width: 600px;
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.logo {
  width: 160px;
  align-self: center;
  margin-bottom: 25px;
}
.input-group {
  width: 100%;
}
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.button {
  padding: 10px 20px;
  background-color: #dc0f37;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #dc0f37;
}

.results {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.intent,
.entity {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.intent-title,
.entity-title {
  color: #333;
  font-weight: bold;
}

.confidence {
  font-size: 0.8em;
  color: #666;
}

/* Adicionalmente, você pode querer adicionar algumas regras globais */
body {
  display: flex;
  flex-direction: column;
  background-color: #e9ebee;
  color: #333333;
  line-height: 1.6;
  padding: 20px;
}

h2 {
  color: #333;
}
</style>
